import Vue from "vue";
import Router from "vue-router";
import { userService } from "../services/user.service";
// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const Swagger = () => import("@/views/services/Swagger");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

const loginPath = "/login";

Vue.use(Router);

export const router = new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: DefaultContainer,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "services/:id?",
          meta: { label: "Services" },
          name: "Services",
          component: Swagger,
        },
      ],
    },
    {
      path: loginPath,
      name: "Login",
      component: Login,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  console.log(`[router/index.js] Navigating from ${from.path} to ${to.path}`);
  console.log(
    "[router/index.js] DEBUG env variable:",
    process.env.VUE_APP_GOOGLE_CLIENT_ID
  );

  const publicPages = [loginPath];
  const authRequired = !publicPages.includes(to.path);
  console.log("[router/index.js] authRequired:", authRequired);

  const isLoggedIn = await userService.isLoggedIn();
  console.log("[router/index.js] isLoggedIn:", isLoggedIn);

  if (authRequired && !isLoggedIn) {
    console.log(`[router/index.js] Redirecting to login page`);
    return next({
      path: loginPath,
      query: { returnUrl: to.path },
    });
  } else if (isLoggedIn && to.path === loginPath) {
    console.log(
      `[router/index.js] User already logged in, redirecting to home`
    );
    return next({ name: "Home" });
  }
  console.log(`[router/index.js] Not redirecting to login page`);
  next();
});
