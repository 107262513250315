import Vue from 'vue';

export const userService = {
  isLoggedIn,
  getUserProfile,
  logoutUser
};

async function getUserProfile() {
  const profile = (await Vue.GoogleAuth).currentUser.get().getBasicProfile();
  return {
    fullName: profile.getName(),
    givenName: profile.getGivenName(),
    familyName: profile.getFamilyName(),
    imageUrl: profile.getImageUrl(),
    email: profile.getEmail()
  };
}

async function isLoggedIn() {
  const isLoggedIn = (await Vue.GoogleAuth).isSignedIn.get();
  console.log('[user.service.js] User logged in:', isLoggedIn);
  return isLoggedIn;
}

async function logoutUser() {
  const auth2 = await Vue.GoogleAuth;
  await auth2.disconnect();
}
