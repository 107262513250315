// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es/array';
import 'core-js/es/promise';
import 'core-js/es/string';
// import cssVars from 'css-vars-ponyfill'
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import { LoaderPlugin } from 'vue-google-login';
import App from './App';
import { router } from './router';

// todo
// cssVars()

Vue.use(BootstrapVue);
Vue.use(LoaderPlugin, { client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID });

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  }
});
